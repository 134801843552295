// stereoGallery
.lg-outer.lg-grab .sg-canvas {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.lg-outer.lg-grabbing .sg-canvas {
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
}
// .lg-outer .lg-inner.lg-loaded {
//     background: url(img/loading.gif) no-repeat scroll center center transparent;
// }

// threedGallery
.lg-outer.lg-grab .tdg-canvas {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.lg-outer.lg-grabbing .tdg-canvas {
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
}
.lg-outer .lg-inner.lg-loaded:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../img/loading.gif) no-repeat scroll center center transparent;
}
// tdg-help
.tdg-help-overlay .card {
    background: transparent;
    text-align: center;
    color: #fff;
}
.tdg-help-overlay .card i {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 32px;
}
.tdg-help-overlay .card p {
    font-size: 16px;
}
.lg-outer .tdg-help-overlay {
    background-color: rgba(0,0,0,.25);
    bottom: 0;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1080;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear .18s,opacity .18s linear 0s;
    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
}
.lg-outer.tdg-help-active .tdg-help-overlay {
    transition-delay: 0s;
    transform: translate3d(0,0,0);
    opacity: 1;
    visibility: visible;
}
.tdg-help-overlay .card {
    text-align: center !important;
}
.tdg-help-overlay .card img {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
}
// tdg-ar
.tdg-qr {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    border-radius: 5px;
    text-align: center;
    margin: 0;
    display: none;
    padding: 15px;
    z-index: 1082;
    justify-content: center;
    align-items: center;
}
.lg-outer.tdg-qr-active .tdg-qr {
    display: flex;
    flex-direction: column;
}
.tdg-qr .tdg-content {
    background-color: #fff;
    display: grid;
    padding: 30px;
}
.tdg-qr .qr-code-image {
    width: 100%;
    padding: 10px 40px;
}
.tdg-qr .title {
    text-transform: uppercase;
    font-size: 16px;
}
.tdg-qr .small {
    font-size: 14px;
}
.tdg-fonts {
    color: #ff1700;
    cursor: pointer;
    float: right;
    font-size: 34px;
    line-height: 27px;
    padding: 10px 0px;
    text-align: center;
    width: 65px;
    text-decoration: none!important;
    outline: medium none;
    transition: color .2s linear;
}
@media (max-width: 400px) {
.lg-toolbar .lg-icon {
    font-size: 26px !important;
    width: 40px !important;
}
.tdg-fonts {
    font-size: 32px !important;
    width: 56px !important;
}
}

