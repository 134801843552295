body,div,dl,dt,dd,ul,ol,li,pre,form,fieldset,input,textarea,p,blockquote,th,td {
    font-family: 'Gilroy-Regular', serif;
    margin: 0;
}
h1, h2, h3 {
    font-family: Gilroy-Regular,serif;
    margin: 0;
    letter-spacing: .2rem;
    text-transform: uppercase;
}
h4, h5 {
    font-family: Gilroy-Regular,serif;
    margin: 0;
    letter-spacing: .15rem;
    text-transform: uppercase;
}

      
   @font-face {
    font-family: 'Gilroy-Bold';
    font-display: block;
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('Gilroy-Bold'),
         url('../fonts/Gilroy-Bold.woff') format('woff'),
         url('../fonts/Gilroy-Bold.ttf') format('truetype'),
         url('../fonts/Gilroy-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
   }
   @font-face {

    font-family: 'Gilroy-ExtraBold';
    font-display: block;
    src: url('../fonts/Gilroy-Heavy.eot');
    src: url('../fonts/Gilroy-Heavy.eot?#iefix') format('Gilroy-ExtraBold'),
         url('../fonts/Gilroy-Heavy.woff') format('woff'),
         url('../fonts/Gilroy-Heavy.ttf') format('truetype'),
         url('../fonts/Gilroy-Heavy.svg') format('svg');
    font-weight: normal;
    font-style: normal;
   }

   @font-face {

    font-family: 'Gilroy-Regular';
    font-display: block;
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('Gilroy-Regular'),
         url('../fonts/Gilroy-Regular.woff') format('woff'),
         url('../fonts/Gilroy-Regular.ttf') format('truetype'),
         url('../fonts/Gilroy-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
   }

   @font-face {

    font-family: 'Gilroy-Medium';
    font-display: block;
    src: url('../fonts/Gilroy-Medium.eot');
    src: url('../fonts/Gilroy-Medium.eot?#iefix') format('Gilroy-Medium'),
         url('../fonts/Gilroy-Medium.woff') format('woff'),
         url('../fonts/Gilroy-Medium.ttf') format('truetype'),
         url('../fonts/Gilroy-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
   }


@font-face {
  font-family: 'social';
  src:  url('../fonts/social.eot?ik6kbr');
  src:  url('../fonts/social.eot?ik6kbr#iefix') format('embedded-opentype'),
    url('../fonts/social.ttf?ik6kbr') format('truetype'),
    url('../fonts/social.woff?ik6kbr') format('woff'),
    url('../fonts/social.svg?ik6kbr#social') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="social-"], [class*=" social-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-pinterest-social-logo:before {
  content: "\e900";
}
.social-viber:before {
  content: "\e901";
}
.social-vk:before {
  content: "\e902";
}
.social-twitter:before {
  content: "\e903";
}
.social-skype:before {
  content: "\e904";
}
.social-facebook:before {
  content: "\e905";
}
.social-youtube:before {
  content: "\e906";
}
.social-telegram:before {
  content: "\e907";
}
.social-behance:before {
  content: "\e908";
}
.social-sharethis:before {
  content: "\e909";
}
.social-yandex:before {
  content: "\e90a";
}
.social-instagram:before {
  content: "\e90b";
}
.social-whatsapp:before {
  content: "\e90c";
}
.social-instagram-1:before {
  content: "\e90d";
}








@font-face {
  font-family: 'ico';
  src:  url('../fonts/ico.eot?9p1axw');
  src:  url('../fonts/ico.eot?9p1axw#iefix') format('embedded-opentype'),
    url('../fonts/ico.ttf?9p1axw') format('truetype'),
    url('../fonts/ico.woff?9p1axw') format('woff'),
    url('../fonts/ico.svg?9p1axw#ico') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ico' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fruit:before {
  content: "\e9af";
}
.icon-tick:before {
  content: "\e9b0";
}
.icon-star:before {
  content: "\e9b1";
}
.icon-pizza:before {
  content: "\e9b2";
}
.icon-tag:before {
  content: "\e9b3";
}
.icon-pay:before {
  content: "\e9b4";
}
.icon-close:before {
  content: "\e9b5";
}
.icon-eye-1:before {
  content: "\e9b6";
}
.icon-copyright:before {
  content: "\e9b7";
}
.icon-racket:before {
  content: "\e9b8";
}
.icon-user:before {
  content: "\e9b9";
}
.icon-download:before {
  content: "\e9ba";
}
.icon-bell:before {
  content: "\e9bb";
}
.icon-menu-1:before {
  content: "\e9bc";
}
.icon-fish:before {
  content: "\e9bd";
}
.icon-beach:before {
  content: "\e9be";
}
.icon-photograph:before {
  content: "\e9bf";
}
.icon-add:before {
  content: "\e9c0";
}
.icon-link:before {
  content: "\e9c1";
}
.icon-supermarket:before {
  content: "\e9c2";
}
.icon-thunder:before {
  content: "\e9c3";
}
.icon-right-arrow:before {
  content: "\e9c4";
}
.icon-watch:before {
  content: "\e9c5";
}
.icon-gear:before {
  content: "\e9c6";
}
.icon-contract:before {
  content: "\e9c7";
}
.icon-coupon:before {
  content: "\e9c8";
}
.icon-rose:before {
  content: "\e9c9";
}
.icon-key:before {
  content: "\e9ca";
}
.icon-save:before {
  content: "\e9cb";
}
.icon-drop:before {
  content: "\e9cc";
}
.icon-search:before {
  content: "\e9cd";
}
.icon-double-chevron:before {
  content: "\e9ce";
}
.icon-filter:before {
  content: "\e9cf";
}
.icon-grape:before {
  content: "\e9d0";
}
.icon-calendar:before {
  content: "\e9d1";
}
.icon-alert-1:before {
  content: "\e9d2";
}
.icon-search-1:before {
  content: "\e9d3";
}
.icon-down-chevron:before {
  content: "\e9d4";
}
.icon-ui:before {
  content: "\e9d5";
}
.icon-trash:before {
  content: "\e9d6";
}
.icon-desktop:before {
  content: "\e9d7";
}
.icon-kayak:before {
  content: "\e9d8";
}
.icon-expand:before {
  content: "\e9d9";
}
.icon-real-estate:before {
  content: "\e9da";
}
.icon-boat:before {
  content: "\e9db";
}
.icon-target:before {
  content: "\e9dc";
}
.icon-graph:before {
  content: "\e9dd";
}
.icon-refresh:before {
  content: "\e9de";
}
.icon-bread:before {
  content: "\e9df";
}
.icon-crab:before {
  content: "\e9e0";
}
.icon-chat:before {
  content: "\e9e1";
}
.icon-celsius:before {
  content: "\e9e2";
}
.icon-car:before {
  content: "\e9e3";
}
.icon-can:before {
  content: "\e9e4";
}
.icon-expand-1:before {
  content: "\e9e5";
}
.icon-carrot:before {
  content: "\e9e6";
}
.icon-island:before {
  content: "\e9e7";
}
.icon-help:before {
  content: "\e9e8";
}
.icon-attach:before {
  content: "\e9e9";
}
.icon-flight:before {
  content: "\e9ea";
}
.icon-server:before {
  content: "\e9eb";
}
.icon-clock:before {
  content: "\e9ec";
}
.icon-unlock:before {
  content: "\e9ed";
}
.icon-edit:before {
  content: "\e9ee";
}
.icon-ui-1:before {
  content: "\e9ef";
}
.icon-phone:before {
  content: "\e9f0";
}
.icon-mail:before {
  content: "\e9f1";
}
.icon-art:before {
  content: "\e9f2";
}
.icon-branch:before {
  content: "\e9f3";
}
.icon-egg:before {
  content: "\e9f4";
}
.icon-boxer:before {
  content: "\e9f5";
}
.icon-bug:before {
  content: "\e9f6";
}
.icon-wild-life:before {
  content: "\e9f7";
}
.icon-food-and-restaurant:before {
  content: "\e9f8";
}
.icon-mountain:before {
  content: "\e9f9";
}
.icon-chicken:before {
  content: "\e9fa";
}
.icon-bike:before {
  content: "\e9fb";
}
.icon-zoom:before {
  content: "\e9fc";
}
.icon-beach-1:before {
  content: "\e9fd";
}
.icon-target-1:before {
  content: "\e9fe";
}
.icon-food:before {
  content: "\e9ff";
}
.icon-wave:before {
  content: "\ea00";
}
.icon-eye:before {
  content: "\ea01";
}
.icon-burger:before {
  content: "\ea02";
}
.icon-weather:before {
  content: "\ea03";
}
.icon-sprout:before {
  content: "\ea04";
}
.icon-menu:before {
  content: "\ea05";
}
.icon-photo:before {
  content: "\ea06";
}
.icon-badminton:before {
  content: "\ea07";
}
.icon-alert:before {
  content: "\ea08";
}
.icon-yacht:before {
  content: "\e900";
}
.icon-anchor:before {
  content: "\e901";
}
.icon-flashlight:before {
  content: "\e902";
}
.icon-lighter:before {
  content: "\e903";
}
.icon-hatchet:before {
  content: "\e904";
}
.icon-trailer:before {
  content: "\e905";
}
.icon-trolley:before {
  content: "\e906";
}
.icon-steering-wheel:before {
  content: "\e907";
}
.icon-biometric:before {
  content: "\e908";
}
.icon-backpack:before {
  content: "\e909";
}
.icon-mountains:before {
  content: "\e90a";
}
.icon-distance:before {
  content: "\e90b";
}
.icon-flip-flops:before {
  content: "\e90c";
}
.icon-gps:before {
  content: "\e90d";
}
.icon-globe-grid:before {
  content: "\e90e";
}
.icon-bell1:before {
  content: "\e90f";
}
.icon-reply:before {
  content: "\e910";
}
.icon-writing:before {
  content: "\e911";
}
.icon-24-hours:before {
  content: "\e912";
}
.icon-refresh1:before {
  content: "\e913";
}
.icon-test:before {
  content: "\e914";
}
.icon-brain:before {
  content: "\e915";
}
.icon-link1:before {
  content: "\e916";
}
.icon-plus:before {
  content: "\e917";
}
.icon-key1:before {
  content: "\e918";
}
.icon-piggy-bank:before {
  content: "\e919";
}
.icon-rocket:before {
  content: "\e91a";
}
.icon-calculator:before {
  content: "\e91b";
}
.icon-dashboard:before {
  content: "\e91c";
}
.icon-infinity:before {
  content: "\e91d";
}
.icon-clip:before {
  content: "\e91e";
}
.icon-first:before {
  content: "\e91f";
}
.icon-wifi:before {
  content: "\e920";
}
.icon-briefcase:before {
  content: "\e921";
}
.icon-calendar1:before {
  content: "\e922";
}
.icon-history:before {
  content: "\e923";
}
.icon-instagram:before {
  content: "\e924";
}
.icon-music:before {
  content: "\e925";
}
.icon-student:before {
  content: "\e926";
}
.icon-home:before {
  content: "\e927";
}
.icon-monitor:before {
  content: "\e928";
}
.icon-timetable:before {
  content: "\e929";
}
.icon-alarm:before {
  content: "\e92a";
}
.icon-feather:before {
  content: "\e92b";
}
.icon-right-arrow1:before {
  content: "\e92c";
}
.icon-play:before {
  content: "\e92d";
}
.icon-study:before {
  content: "\e92e";
}
.icon-twitter:before {
  content: "\e92f";
}
.icon-email:before {
  content: "\e930";
}
.icon-puzzle:before {
  content: "\e931";
}
.icon-university:before {
  content: "\e932";
}
.icon-award:before {
  content: "\e933";
}
.icon-copy:before {
  content: "\e934";
}
.icon-edit1:before {
  content: "\e935";
}
.icon-phone-call:before {
  content: "\e936";
}
.icon-print:before {
  content: "\e937";
}
.icon-research:before {
  content: "\e938";
}
.icon-art1:before {
  content: "\e939";
}
.icon-edit11:before {
  content: "\e93a";
}
.icon-idea:before {
  content: "\e93b";
}
.icon-book:before {
  content: "\e93c";
}
.icon-photo-camera:before {
  content: "\e93d";
}
.icon-tag1:before {
  content: "\e93e";
}
.icon-bookmark:before {
  content: "\e93f";
}
.icon-ebook:before {
  content: "\e940";
}
.icon-star1:before {
  content: "\e941";
}
.icon-chevron:before {
  content: "\e942";
}
.icon-mortarboard:before {
  content: "\e943";
}
.icon-pay1:before {
  content: "\e944";
}
.icon-cancel:before {
  content: "\e945";
}
.icon-folder:before {
  content: "\e946";
}
.icon-portfolio:before {
  content: "\e947";
}
.icon-check:before {
  content: "\e948";
}
.icon-diploma:before {
  content: "\e949";
}
.icon-sun:before {
  content: "\e94a";
}
.icon-fullscreen:before {
  content: "\e94b";
}
.icon-open-book:before {
  content: "\e94c";
}
.icon-settings:before {
  content: "\e94d";
}
.icon-eye1:before {
  content: "\e94e";
}
.icon-presentation:before {
  content: "\e94f";
}
.icon-up-arrow:before {
  content: "\e950";
}
.icon-pin:before {
  content: "\e951";
}
.icon-push-pin:before {
  content: "\e952";
}
.icon-school:before {
  content: "\e953";
}
.icon-medal:before {
  content: "\e954";
}
.icon-stop:before {
  content: "\e955";
}
.icon-wall-clock:before {
  content: "\e956";
}
.icon-arithmetic:before {
  content: "\e957";
}
.icon-heart:before {
  content: "\e958";
}
.icon-trash1:before {
  content: "\e959";
}
.icon-joke:before {
  content: "\e95a";
}
.icon-sport:before {
  content: "\e95b";
}
.icon-target1:before {
  content: "\e95c";
}
.icon-library:before {
  content: "\e95d";
}
.icon-lock:before {
  content: "\e95e";
}
.icon-youtube:before {
  content: "\e95f";
}
.icon-chemistry:before {
  content: "\e960";
}
.icon-shopping-cart:before {
  content: "\e961";
}
.icon-up-arrow-1:before {
  content: "\e962";
}
.icon-leaf:before {
  content: "\e963";
}
.icon-locker:before {
  content: "\e964";
}
.icon-next:before {
  content: "\e965";
}
.icon-multimedia-option:before {
  content: "\e966";
}
.icon-phone-call-1:before {
  content: "\e967";
}
.icon-vision:before {
  content: "\e968";
}
.icon-flag:before {
  content: "\e969";
}
.icon-school-1:before {
  content: "\e96a";
}
.icon-thunderbolt:before {
  content: "\e96b";
}
.icon-chat1:before {
  content: "\e96c";
}
.icon-cut:before {
  content: "\e96d";
}
.icon-space:before {
  content: "\e96e";
}
.icon-dislike:before {
  content: "\e96f";
}
.icon-magnifying-glass:before {
  content: "\e970";
}
.icon-wind:before {
  content: "\e971";
}
.icon-file:before {
  content: "\e972";
}
.icon-minimize:before {
  content: "\e973";
}
.icon-screenshot:before {
  content: "\e974";
}
.icon-content-writing:before {
  content: "\e975";
}
.icon-picture:before {
  content: "\e976";
}
.icon-usb:before {
  content: "\e977";
}
.icon-back:before {
  content: "\e978";
}
.icon-edit-image:before {
  content: "\e979";
}
.icon-see:before {
  content: "\e97a";
}
.icon-clean:before {
  content: "\e97b";
}
.icon-down-arrow:before {
  content: "\e97c";
}
.icon-goal:before {
  content: "\e97d";
}
.icon-diamond:before {
  content: "\e97e";
}
.icon-feedback:before {
  content: "\e97f";
}
.icon-trash11:before {
  content: "\e980";
}
.icon-bell-1:before {
  content: "\e981";
}
.icon-diagram:before {
  content: "\e982";
}
.icon-sketchbook:before {
  content: "\e983";
}
.icon-flash:before {
  content: "\e984";
}
.icon-innovation:before {
  content: "\e985";
}
.icon-share:before {
  content: "\e986";
}
.icon-cart:before {
  content: "\e987";
}
.icon-video-camera:before {
  content: "\e988";
}
.icon-world:before {
  content: "\e989";
}
.icon-full-battery:before {
  content: "\e98a";
}
.icon-pin-1:before {
  content: "\e98b";
}
.icon-png:before {
  content: "\e98c";
}
.icon-cdr:before {
  content: "\e98d";
}
.icon-cold:before {
  content: "\e98e";
}
.icon-timer:before {
  content: "\e98f";
}
.icon-like:before {
  content: "\e990";
}
.icon-macro:before {
  content: "\e991";
}
.icon-responsibility:before {
  content: "\e992";
}
.icon-pros-and-cons:before {
  content: "\e993";
}
.icon-radio:before {
  content: "\e994";
}
.icon-wallet:before {
  content: "\e995";
}
.icon-back-1:before {
  content: "\e996";
}
.icon-gear1:before {
  content: "\e997";
}
.icon-profits:before {
  content: "\e998";
}
.icon-random:before {
  content: "\e999";
}
.icon-shield:before {
  content: "\e99a";
}
.icon-vector:before {
  content: "\e99b";
}
.icon-arrow:before {
  content: "\e99c";
}
.icon-bank:before {
  content: "\e99d";
}
.icon-calendar11:before {
  content: "\e99e";
}
.icon-alarm-clock:before {
  content: "\e99f";
}
.icon-clip1:before {
  content: "\e9a0";
}
.icon-donation:before {
  content: "\e9a1";
}
.icon-analytics:before {
  content: "\e9a2";
}
.icon-abacus:before {
  content: "\e9a3";
}
.icon-oil:before {
  content: "\e9a4";
}
.icon-chat11:before {
  content: "\e9a5";
}
.icon-aircraft:before {
  content: "\e9a6";
}
.icon-sunset:before {
  content: "\e9a7";
}
.icon-first-aid-kit:before {
  content: "\e9a8";
}
.icon-air-balloon:before {
  content: "\e9a9";
}
.icon-bonfire:before {
  content: "\e9aa";
}
.icon-lighthouse:before {
  content: "\e9ab";
}
.icon-map:before {
  content: "\e9ac";
}
.icon-ice-cream:before {
  content: "\e9ad";
}
.icon-matches:before {
  content: "\e9ae";
}

