/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
	padding-top: 0;
	padding-bottom: 80px;
	color: #222;
	font-size: 16px;
	background: #fff;
}
@media (max-width: 768px) {
    body {
        padding-bottom: 120px;
    }
}

video,
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
outline: 0!important;
outline-color: transparent!important;
outline-width: 0!important;
outline-style: none!important; 
box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;  
}
::-moz-selection{
    background-color:#ff1700;
	border-radius: 5px;
    color:#fff
}

::selection{
    background-color:#ff1700;
	border-radius: 5px;
    color:#fff
}


.small, small {
    font-size: 16px;
    font-weight: 400;
}
.container {
    padding-right: 10px;
    padding-left: 10px;
}
.row {
    margin-right: -10px;
    margin-left: -10px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 10px;
    padding-left: 10px;
}
body.container-home {
	background: #000;
}
.h5, h5 {
	font-size: 20px;
}
.h2, h2 {
	font-size: 24px;
}
.h4, h4 {
    font-size: 24px;
}
@media (max-width: 768px) {
.h5, h5 {
	font-size: 20px !important;
}
.h2, h2 {
	font-size: 20px !important;
}
} 

.jumbotron {
	padding: 150px 15px 30px 15px;
	color: #fff;
	margin-bottom: 15px;
	background-color: #1c1c1d;
	border-radius: 0;
}
.dropdown-list-group {
	position: relative;
	top: 0;
	width: 100%;
	padding: 0;
	margin-bottom: 15px;
	margin: 0;

}
a {
	color: #222;
	text-decoration: none;
	background-color: transparent;
	    transition: color .15s ease-in-out;
}
 

a:hover {
    color: #222;
    text-decoration: none;
}

a.card-img {
	cursor: pointer;
}

span a, p a {
  text-decoration: underline;
  color: #ff1700;
}
span a:hover, p a:hover {
  text-decoration: none;
  color: #ff1700;
}

.custom-control-label a {
  text-decoration: underline;
  color: #ff1700;
}
.custom-control-label a:hover {
  text-decoration: none;
  color: #ff1700;
}


.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.red {
	color: #ff1700;
}

.dropdown-list-group .list-group-item {
	position: relative;
	display: block;
	padding: 0;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,.125);
}
.dropdown-list-group .dropdown-item {
	display: block;
	width: 100%;
	padding: 15px ;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.adv-container {
	margin: 60px 0;
}
.btn-search i{
	cursor: pointer;
	font-size: 30px;
	color: #fff;
	line-height: normal;
	height: 30px;
	display: block;
	margin-right: 15px;
	position: absolute;
	left: 0;
	top: 0;
}
.btn-search {
	position: relative;
}
.btn-search span {

	color: #fff;
	text-decoration: none;
	font-size: 16px;
	cursor: pointer;
}
.btn-search a {
	padding-left: 35px;
	margin-right: 15px;
}


.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.bd-img {
	width: 100%;
}
.phone-top, .phone-top:hover {
	font-family: 'Gilroy-Bold', serif;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	margin-right: 15px;
}
.cart-top, .cart-top:hover {
	font-family: 'Gilroy-Bold', serif;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	margin-right: 15px;
}
p span {
    font-family: Gilroy-Regular,serif !important;
    font-size: 16px !important;
}
span {
    font-family: Gilroy-Regular,serif;
}
.modal-body p {
	text-align: justify !important;
}


.info-box {
	position: relative;
	padding-left: 35px;
}
.info-box i{
	cursor: pointer;
	font-size: 30px;
	color: #fff;
	line-height: normal;
	height: 30px;
	left: 0;
	position: absolute;
}

.cart-box {
	position: relative;
	padding-left: 35px;
}
.cart-box i{
	cursor: pointer;
	font-size: 30px;
	color: #fff;
	line-height: normal;
	height: 30px;
	left: 12px;
	position: absolute;
}

.comment-carousel {
	height: 300px;
	padding: 50px 0;
}
.comment-carousel .carousel-control-next {
	right: -80px;
}


.comment-carousel .carousel-control-prev {
	left: -80px;
}

.comment-carousel span:before{
	font-size: 50px;
	color: #222;
}
/* form */


.form-control {
    background-color: #fff;
    border: 2px solid #222;
    border-radius: 0;
    padding: 15px 15px;
    font-size: 16px;
    height: calc(50px + 1rem + 2px);
}

.form-horizontal label {
    margin-top: 10px;
}


.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 2px solid #222222;
    border-radius: 0;
    margin-left: -1px;
}


.input-search {
	font-size: 34px !important;
	padding: 0 25px 0 20px !important;
	color: #fff;
	background-color: #1c1c1d;
	border: none;
}
.input-group-lg>.custom-select, .input-group-lg>.form-control:not(textarea) {
	height: calc(50px + 1rem + 2px);
}
.input-group-lg>.custom-select, .input-group-lg>.form-control, .input-group-lg>.input-group-append>.btn, .input-group-lg>.input-group-append>.input-group-text, .input-group-lg>.input-group-prepend>.btn, .input-group-lg>.input-group-prepend>.input-group-text {
    padding: 15px 20px;
	font-size: 16px;
	line-height: normal;
	border-radius: 0;
}
.modal-low {
	background: none;
}
.modal-low .form-control {
	border: none;

}
.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}
@media (min-width: 576px) {
	.modal-dialog {
		max-width: 540px;
		margin: 15px auto;
	}

}
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 30px;
    border-bottom: none;
    position: absolute;
    width: 100%;
    height: 55px;
    z-index: 9;
}
.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: none;
	border-radius: 15px;
	outline: 0;
}
.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}


.modal-open .modal {
	padding-left: 0 !important;
}

.owl-item .card-body {
	    position: relative;
   height: 180px;
}
.owl-item .item .card {
	width: 100%;
	height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}
@media (max-width: 768px) {
	.owl-item .item .card {
		width: 100% !important;

    }

}
.owl-dots {
	display: none;
}


.card-product .card-product-btn {
    width: 100%;
    padding: 0 25px;
    top: 140px;
    left: 0;
    position: absolute;
    opacity: 0;
    transition: all .15s ease-in-out;

}
.card-product:hover .card-product-btn {
    opacity: 1;
    transition: all .15s ease-in-out;
}
.card-product img {
	opacity: 1;
	transition: all .15s ease-in-out;
}
.card-product:hover img {
	opacity: 0.2;
	transition: all .15s ease-in-out;
}
.modal-title {
	font-size: 24px;
}
.modal-header .close {
	padding: 0 15px;
	margin: -1rem -1rem -1rem auto;
	font-size: 50px;
}
.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #1c1c1d;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgb(255, 23, 0);
}
.custom-control-label::before {
position: absolute;
    top: 2px;
    left: -25px;
    display: block;
    width: 20px;
    height: 20px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #222 solid 2px;
}
.custom-control-label::after {
    position: absolute;
    top: 0px;
    left: -27px;
    display: block;
    width: 25px;
    height: 25px;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #ff1700;
    border-color: #ff1700;
    background-color: #ff1700;
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.home-slider .bd-placeholder-img {
	border-radius: 0;
}
.home-slider {
	background: #1c1c1d

}

.home-slider .carousel {
	margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.home-slider .carousel-caption {
	bottom: 35%;
	z-index: 10;
}
.fixed-top.bg-dark.scrolled {
	background-color: #1c1c1d !important;
	box-shadow: 15px 20px 32px rgba(95, 95, 95, 0.2), 2px 2px 7px rgba(0, 0, 0, 0.15);
	border-color: transparent;

}
.fixed-top.bg-dark {
	background-color: transparent!important;
	box-shadow: none;
	border-color: transparent;

}
/* Declare heights because of positioning of img element */
.carousel-caption h1, .carousel-caption h2 {
	font-size: 80px;
	line-height: 75px;
	margin-bottom: 20px;
}


.home-slider .carousel-inner {
	height: 100vh;
}
.home-slider .carousel-item {
	height: 100%;
}

.home-slider .carousel-item > img {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	min-width: 100%;
	height: auto;
}
.icon-col i:before {
	font-size: 60px;
	color: #fff;
	line-height: 60px;
}
.icon-col-black i:before {
font-size: 55px;
    color: #fff;
    line-height: 75px;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    text-align: center;
    justify-content: center!important;
}
@media (max-width: 991px) {
	.breadcrumb {
    margin-top: 115px !important;
	}
}


.breadcrumb-item a {
    border-bottom: 2px solid #fff;
    transition: all .15s ease-in-out;
    display: initial;
    padding: 3px 0;
}
.breadcrumb-item a:hover {
   border-bottom: 2px solid transparent;
       text-decoration: none;
}
.card-title {
	margin-top: 5px;
	margin-bottom: 10px;
}
.card-body .icon-col {
	margin: 15px 0;
}
.card-text {
	margin-bottom: 10px;
}
.card-price {
	font-family: Gilroy-ExtraBold,serif;
    font-size: 24px;
    margin-top: -5px;
}
.card-price span {
		font-family: Gilroy-ExtraBold,serif;
    font-size: 24px;
    margin-top: -5px;
}
.card-header {
	padding: 30px 30px;
	background-color: transparent;
}
.card-accordion {
	padding: 30px 30px;
	margin-bottom: 0;
	background-color: #1c1c1d;
	border-bottom: 1px solid #1c1c1d;
	color: #fff;
}

.card-accordion .media-body {
	color: #fff;
}

.text-primary {
	color: #1c1c1d!important;
}
.bg-primary {
	background-color: #1c1c1d!important;
}
.bg-primary-gradient {
	background-image: linear-gradient(20deg,#9e55ff,#1c1c1d);
}
.screen-page .page-header {
	padding: calc(1em + 4vh) 0 calc(1em + 2vh);
}
.screen-page {
	text-align: center;
	display: flex;
	overflow: hidden;
}
.screen-page-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;
	width: 100%;
}
.screen-page-box {
	position: relative;
	z-index: 1;
	color: #fff;
}
.screen-page-box .page-description {
	padding: 15px 0;

}
@media (min-width: 1200px) {
	.screen-page-box .page-title {
		font-size: 490px;
		line-height: .75;
	}
}
@media (min-width: 768px) {
	.screen-page-box .page-title {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		font-size: 400px;
		    color: rgba(255, 255, 255, 0.2);
		transform: translate(-50%,-50%);
	}
}
.screen-page-box .page-title {
	font-size: 430px;
	font-family: 'Gilroy-ExtraBold', serif;
	line-height: 1;
	    color: rgba(255, 255, 255, 0.2);
}
.card {
position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0;
    border-radius: 0;

}
.card-box {
	margin-bottom: 20px;

}



.list-group-item:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
}


.carousel-indicators li {
	box-sizing: content-box;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	width: 35px;
	height: 15px;
	margin-right: 5px;
	margin-left: 5px;
	margin-bottom: 10px;
	text-indent: -999px;
	cursor: pointer;
	background-color: transparent;
	background-clip: padding-box;
	border-top: none;
	border-bottom: none;
	opacity: 0.4;
	transition: opacity .6s ease;
	border-radius: 15px;
	border: 4px solid #fff;
}  
.carousel-indicators .active {
	opacity: 1;
	background-color: transparent;
}
.card-pay .card {
	color: #fff;
	background-color: #1c1c1d;
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 15px;
}
.card-group.card-pay .card {
	border-radius: 0;
}

.card-body {
	padding: 15px;
	padding-top: 15px;
}
.card-gallery-body {
	padding: 0 15px 15px;
}
.tab-card-body .card {
	color: #222;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tab-card-body .card:hover {
	text-decoration: none;
	box-shadow: 15px 20px 32px rgba(95, 95, 95, 0.2), 2px 2px 7px rgba(0, 0, 0, 0.15);
	border-color: transparent;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.store-body {
	display: flex;
	flex-direction: row;
	padding: 15px;
	padding-top: 30px;
}
.store-body i {
	border: 1px solid #1c1c1d;
	color: #1c1c1d;
	padding: 3px 4px;
	font-size: 16px;
	line-height: 18px;
	border-radius: 50%;
	width: 26px;
	height: 26px;
	display: inline-block;
	margin-right: 5px;
}
.store-body form {
padding-top: 5px;
    margin-top: 15px;

}
.product-info .product-gallery {
	display: flex;
	flex-direction: row;
}

.product-gallery-featured {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 15px 0;
}

.product-gallery-thumbnails .thumbnails-list li {
	margin-bottom: 5px;
	cursor: pointer;
	position: relative;
	width: 70px;
	height: 70px;
}

.thumbnails-list li img {
	display: block;
	width: 100%;
}


.last-questions-list li {
	margin-bottom: 20px;
}

.last-questions-list li span {
	padding-left: 10px;
}
/* Featurettes
------------------------- */

.featurette-divider {
	margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
	font-weight: 300;
	line-height: 1;
	 letter-spacing: .2rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
	/* Bump up size of carousel content */
	.carousel-caption p {
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
		line-height: 1.4;
	}

	.featurette-heading {
		font-size: 24px;
	}
}

@media (min-width: 62em) {
	.featurette-heading {
        margin-top: 32px !important;
    margin-bottom: 40px;
	}
}

/* adv */

.icon-adv {
	position: relative;
	margin: 15px 0;
	width: 140px;
	height: 140px;
	display: inline-block;
	background-color: #1c1c1d; 
	border-radius: 50%;
	text-align: center;
}
.icon-adv i:before {
	font-size: 80px;
	color: #fff;
	line-height: 140px;
}
.marketing .col-lg-4 {
	margin-bottom: 15px;
	text-align: left;
}
.marketing h2 {
	font-weight: 400;
}
.adv-container p {
	margin: 15px 0;
} 


/* button styles */
.btn-block {
	padding: 15px 5px !important;
}


.btn {
font-weight: 400;
    color: #fff;
    border: 2px solid transparent;
    padding: 4px 14px !important;
    line-height: 25px;
    height: 36px;
    font-size: 14px;
    border-radius: 40px;
    text-transform: uppercase;
}
.btn:hover {
	color: #222;
}
.btn:focus, .btn.focus {
	box-shadow: none;
}


.btn-primary {
    color: #fff !important;
    background-color: #ff1700;
    border-color: #ff1700;
}

.btn-primary:hover {
    color: #222 !important;
    background-color: transparent !important;
    border-color: #ff1700;
}

.btn-primary:focus, .btn-primary.focus {
	box-shadow: none;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #ff1700;
    border-color: #ff1700;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff1700;
    border-color: #ff1700;
}
.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #ff1700;
    border-color: #ff1700;
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-primary {
	color: #222;
	border-color: #1c1c1d;
}

.btn-outline-primary:hover {
	color: #222;
	background-color: transparent;
	border-color: #222;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
	box-shadow: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	color: #fff;
	background-color: #1c1c1d;
	border-color: #1c1c1d;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #1c1c1d;
	border-color: #1c1c1d;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}



.btn-light {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}

.btn-light:hover {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-light:focus, .btn-light.focus {
	box-shadow: none;
}

.btn-light.disabled, .btn-light:disabled {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-secondary {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}

.btn-secondary:hover {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-secondary:focus, .btn-secondary.focus {
	box-shadow: none;
}

.btn-secondary.disabled, .btn-secondary:disabled {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #222;
	background-color: #fff;
	border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-dark {
	color: #222;
    background-color: transparent;
    border-color: #000000;
}



.btn-dark:hover {
	color: #fff;
	background-color: #000;
	border-color: #000;
}

.btn-dark:focus, .btn-dark.focus {
	box-shadow: none;
}

.btn-dark.disabled, .btn-dark:disabled {
	color: #fff;
	background-color: #1c1c1d;
	border-color: #1c1c1d;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #ff1700;
    border-color: #ff1700;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-group-sm>.btn, .btn-sm {
	padding: 15px 15px;
	font-size: 16px;
	line-height: normal;
	border-radius: 15px;
}

/* navbar */

.nav-pills .nav-link {
	border-radius: 15px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	color: #fff;
	background-color: #1c1c1d;
}
.nav-link {
	display: block;
    padding: 0px 0px;
}
.nav-item {
	    padding: 15px 10px;
}
.navbar-light .navbar-nav .nav-link {
	color: rgb(64, 64, 64);
}
.btco-menu li > a {
	padding: 10px 15px;
	color: #000;
}

.btco-menu .active a:focus,
.btco-menu li a:focus ,
.navbar > .show > a:focus{
	background: transparent;
	outline: 0;
}

.nav-media li {
    width: 200px;
    padding: 15px 30px;
}

.dropdown-menu {

	border: none;
	border-radius: 15px;
	    min-width: 119px;


}


@media (max-width: 992px) {
	.fixed-top.bg-dark {
		background-color: #000 !important;
	}
	.btn-search, .info-box {
		margin-bottom: 15px
	}
	.btn-search, .cart-box {
		margin-bottom: 15px
	}
	.cart-box {
		margin-top: 0px !important;
	}
	.navbar-top {
		display: block !important;
		float: left !important;
	}
}
@media (max-width: 576px) {
	.comment-carousel {
		height: 450px;
		padding: 50px 0;
	}
	.comment-carousel .carousel-control-next {
		right: -15px;
	}
	.comment-carousel .carousel-control-prev {
		left: -15px;
	}
	.carousel-caption h1, .carousel-caption h2 {
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 20px;
	}
}
@media (max-width: 992px) {
	.carousel-caption h1, .carousel-caption h2 {
		font-size: 45px;
		line-height: 45px;
		margin-bottom: 20px;
	}
}
@media (max-width: 768px) {
	.carousel-caption h1, .carousel-caption h2 {
font-size: 26px;
    line-height: 25px;
	}
.comment-carousel .media img {
	display: none;
}


}
@media (max-width: 1500px) {
.btn-search {
	display: none;
}
}
@media (max-width: 1380px) {

.info-email {
display: none;
}
}

@media (max-width: 998px) {
.btn-search {
	display: block;
}
.info-email {
display: block;
}
}



@media (max-height: 700px) {
	.home-slider .carousel-caption {
		bottom: 10%;
	}
}


/* nav-icon-2 */
.nav-icon-2{
    width: 45px;
    height: 35px;
	margin: 4px 10px;
	position: absolute;
	cursor: pointer;
    right: 30px;
    top: 38px;
	display: inline-block;
transform: rotate(-45deg);
    z-index: 10;
}
.nav-icon-2 span{
	background-color:#ff1700;
	position: absolute;
	border-radius: 0;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);

}
.dropdown-item {
padding: 7px 16px;
    height: 36px;
}
.nav-icon-2 span:nth-child(1){
	width: 50%;
	height:  4px;
	display: block;
	top: 0px;
	left: 10px;
}
.nav-icon-2 span:nth-child(2){
	width: 100%;
	height:  4px;
	display: block;
	    top: 15px;
	left: 0px;
}
.nav-icon-2 span:nth-child(3){
	width: 50%;
	height:  4px;
	display: block;
	    bottom: -2px;
	left: 10px;
}
.nav-icon-2:not(.open):hover span:nth-child(1){
	width: 100%;
	height:  4px;
	display: block;
	top: -2px;
	left: 0px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-2:not(.open):hover span:nth-child(2){
	width: 100%;
	height:  4px;
	display: block;
	    top: 15px;
	left: 0px;
	transition: .4s cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-2:not(.open):hover span:nth-child(3){
	width: 100%;
	height:  4px;
	display: block;
	bottom: -4px;
	left: 0px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-2.open span:nth-child(1){
	left:3px;
	top: 12px;
	width: 30px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
	transform: rotate(90deg);
	transition-delay: 150ms;
}
.nav-icon-2.open span:nth-child(2){
	left:2px;
	top: 20px;
	width: 20px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
	transform: rotate(45deg);
	transition-delay: 50ms;
}
.nav-icon-2.open span:nth-child(3){
	left:14px;
	top: 20px;
	width: 20px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
	transform: rotate(-45deg);
	transition-delay: 100ms;
}
.navbar-dark .navbar-toggler {
	color: transparent;
	border-color: transparent;
}
/* nav-icon-5 */
.nav-icon-5{
	width: 35px;
	height: 30px;
	margin: 10px 10px;
	position: relative;
	cursor: pointer;
	display: inline-block;
}
.nav-icon-5 span{
	background-color:#fff;
	position: absolute;
	border-radius: 2px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
	width:100%;
	height: 4px;
	transition-duration: 500ms
}
.nav-icon-5 span:nth-child(1){
	top:0px;
	left: 0px;
}
.nav-icon-5 span:nth-child(2){
	top:13px;
	left: 0px;
	opacity:1;
}
.nav-icon-5 span:nth-child(3){
	bottom:0px;
	left: 0px;
}
.nav-icon-5:not(.open):hover span:nth-child(1){
	transform: rotate(-3deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(2){
	transform: rotate(3deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(3){
	transform: rotate(-4deg) scaleY(1.1);
}
.nav-icon-5.open span:nth-child(1){
	transform: rotate(45deg);
	top: 13px;
}
.nav-icon-5.open span:nth-child(2){
	opacity:0;
}
.nav-icon-5.open span:nth-child(3){
	transform: rotate(-45deg); 
	top: 13px;
}
/* nav-icon-6 */
.nav-icon-6{
	width: 35px;
	height: 30px;
	margin: 10px 10px;
	position: relative;
	cursor: pointer;
	display: inline-block;
}
.nav-icon-6 span{
	background-color:#fff;
	position: absolute;
	border-radius: 2px;
	transition: .3s cubic-bezier(.8, .5, .2, 1.4);
	width:100%;
	height: 4px;
}
.nav-icon-6 span:nth-child(1){
	top:0px;
	left: 0px;
}
.nav-icon-6 span:nth-child(2){
	top:13px;
	left: 0px;
}
.nav-icon-6 span:nth-child(3){
	bottom:0px;
	left: 0px;
}
.nav-icon-6:not(.open):hover span:nth-child(1){
	transform:  scaleY(1.2);
	left: -5px;
}
.nav-icon-6:not(.open):hover span:nth-child(2){
	transform: rotate(5deg) scaleY(1.1);
}
.nav-icon-6:not(.open):hover span:nth-child(3){
	transform:  scaleY(1.2);
	left: 5px;
}
.nav-icon-6.open span:nth-child(1){
	transform: rotate(45deg) scaleX(0.7);
	top: 13PX;
	left: -8px;
}
.nav-icon-6.open span:nth-child(2){
	transform: scale(0);
	transition-duration: 50ms
}
.nav-icon-6.open span:nth-child(3){
	transform: rotate(-45deg) scaleX(0.7);
	top: 13PX;
	left: 7px;
}
/* nav-icon-1 */
.nav-icon-1{
	width: 30px;
	height: 30px;
	position: relative;
	transition: .1s;
	margin: 10px 10px;
	cursor: pointer;
	display: inline-block;
}
.nav-icon-1 span{
	width: 5px;
	height: 5px;
	background-color: #fff;
	display: block;
	border-radius: 50%;
	position: absolute;

}
.nav-icon-1:hover span{
	transform: scale(1.2);
	transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-1 span:nth-child(1){
	left: 0;
	top: 0;
}
.nav-icon-1 span:nth-child(2){
	left: 12px;
	top: 0;
}
.nav-icon-1 span:nth-child(3){
	right: 0;
	top: 0;
}
.nav-icon-1 span:nth-child(4){
	left: 0;
	top: 12px;
}
.nav-icon-1 span:nth-child(5){
	position: absolute;
	left: 12px;
	top: 12px;
}
.nav-icon-1 span:nth-child(6){
	right: 0px;
	top: 12px;
}
.nav-icon-1 span:nth-child(7){
	left: 0px;
	bottom: 0px;
}
.nav-icon-1 span:nth-child(8){
	position: absolute;
	left: 12px;
	bottom: 0px;
}
.nav-icon-1 span:nth-child(9){
	right: 0px;
	bottom: 0px;
}
.nav-icon-1.open{
	transform: rotate(180deg);
	cursor: pointer;
	transition: .2s cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-1.open span{
	border-radius: 50%;
	transition-delay: 200ms;
	transition: .5s cubic-bezier(.8, .5, .2, 1.4);
}
.nav-icon-1.open span:nth-child(2) {
	left: 6px;
	top: 6px;
}
.nav-icon-1.open span:nth-child(4) {
	left: 6px;
	top: 18px;
}
.nav-icon-1.open span:nth-child(6) {
	right: 6px;
	top: 6px;
}
.nav-icon-1.open span:nth-child(8) {
	left: 18px;
	bottom: 6px;
}
.label-sale {
    position: absolute;
    border-radius: 15px;
    right: 0;
    padding: 3px 8px;
    top: 0;
}
.card-price-old {
	position: relative;
}
.card-price-old:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	background: #212121;
	left: 0;
	top: 58%;
}
.navbar-top {
	float: right;
	display: flex;

}
.navbar-group {
	display: block;
	width: 100%;
}
.navbar-group .btn-search, .navbar-group .info-box {
	margin-top: 15px !important;
}
.cart-box {
    position: absolute;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin-right: 0;
    right: 130px;
    top: 43px;
    width: 32px;
    height: 32px;
    z-index: 5;
}
.cart-box img {
	width: 100%;
}
.cart-box .badge{
    position: absolute;
    top: -5px;
    right: -13px;

}
.badge {
	    border-radius: 50%;
	    padding: 3px 5px;

}
.badge-light {
    color: #222;
    background-color: #ececec;
}
.navbar-lg {
	padding: 1.0rem 1rem;
}

.navbar-lg [class^="col-md-"] {
	padding-right: 0;
	padding-left: 0;
}
.bg-dark {
	background-color: #1c1c1d!important;
}
.social-groub {
	padding-left: 0;
	    margin-bottom: -2px;
	list-style: none;
}
.social-media span{
	font-size: 14px;

}

.social-groub li {
	display: inline-flex;
	margin: 10px;
	margin-left: 0;
	margin-bottom: 2px;
}
.social-groub li i {
	font-size: 24px;
}
.social-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-bottom: 5px;
	    z-index: 1;

}
@media (max-width: 768px) {
.social-footer {
	position:relative;
}
.social-media {
    margin-bottom: 0px !important;
}
}

.social-footer:after {
content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 47%, rgba(255,255,255,1) 100%);
    bottom: -20px;
    left: 0;
    z-index: -1;
        pointer-events: none;
}

 

.dropdown-menu a {
	display: block !important;
	opacity: 1 !important;
}


@media (min-width: 979px) {
	.nav-item.dropdown:hover .nav-link-btn > .dropdown-menu {
    display: block;
    margin: 0;
    left: auto;
    right: 90%;
    top: 6px; 
        padding-right: 4px;
	}

    .navitem-top .nav-item.dropdown:hover .nav-link-btn > .dropdown-menu {
    display: block;
    margin: 0;
    left: auto;
    right: -10px;
    top: 45px;
    padding-right: 4px;
	}




	.dropdown-menu li:hover > .dropdown-menu {
display: block;
    margin: 0;
    right: 100%;
    left: auto;
    top: -8px;
        padding-right: 4px;
	}

}

.navbar-nav .dropdown-menu {
        box-shadow: none;
    background-color: transparent;
}


@media (min-width: 992px) {
	.animate {
		animation-duration: 0.3s;
		-webkit-animation-duration: 0.3s;
		animation-fill-mode: both;
		-webkit-animation-fill-mode: both;
	}
}
@keyframes slideIn {
	0% {
		transform: translateY(1rem);
		opacity: 0;
	}
	100% {
		transform: translateY(0rem);
		opacity: 1;
	}
	0% {
		transform: translateY(1rem);
		opacity: 0;
	}
}
@-webkit-keyframes slideIn {
	0% {
		-webkit-transform: transform;
		-webkit-opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		-webkit-opacity: 1;
	}
	0% {
		-webkit-transform: translateY(1rem);
		-webkit-opacity: 0;
	}
}
.slideIn {
	-webkit-animation-name: slideIn;
	animation-name: slideIn;
}









.dropdown-menu li {
	position: relative;
}
.dropdown-toggle {
	display: none;
}
.nav-icon-group {
    padding-right: 0px;
    padding-left: 20px;

}

.dropdown-menu {
 text-align: right;
}


.dropdown-menu li {
	    background: #fff;
    display: inline-block;
    border-radius: 25px;
    margin-bottom: 4px !important;
    box-shadow: 15px 20px 32px rgba(95, 95, 95, 0.2), 2px 2px 7px rgba(0,0,0,.15);
}



.nav-link-btn {
	text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: font-size .15s ease-out;
}
@media (min-width: 992px) {





	 .dropdown-menu .nav-icon-group::after {
    content: '';
    position: absolute;
    left: 18px;
    width: 10px;
    top: 12px;
    height: 10px;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    transform: rotate(-45deg);
    z-index: 15;
    opacity: 1;
    transition: opacity 1s ease-in-out;
	}



	.navactive.nav-icon-group .nav-link-btn::after {
		opacity: 1;
		transition: opacity 1.5s ease-in-out;

	}


	.dropdown.nav-icon-group .nav-link-btn::after, .dropdown.nav-icon-group::before {
		top: 12px;
	}

	.list-group .nav-icon-group .nav-link-btn::after {
		transform: rotate(-135deg);
		top: 20px;
	}

}
@media (max-width: 992px) {
	.dropdown-toggle {
    display: block;
    color: #000;
    top: 12px;
    left: 0px;
    position: absolute;
    width: 30px;
    height: 100%;
    padding: 0 0;
    z-index: 10;
	}
	.dropdown-menu .dropdown-toggle {
		top: 7px;
		right: 15px;
	}
	.dropdown-list-group .dropdown-menu {
		position: static;
		float: none;
	}


}
.navbar .dropdown-toggle::after {
	display: inline-block;
	position: absolute;
	margin-left: 0;
	vertical-align: 0;
	content: "";
	width: 12px;
	height: 4px;
	border: none;
	background:#ff1700;
	border-radius: 0;
	transform: rotate(45deg);
	transition-delay: 50ms;
	left: 6px;
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
	top: 10px;
}
.dropdown-toggle::before {
	display: inline-block;
	position: absolute;
	margin-left: 0;
	vertical-align: 0;
	content: "";
	width: 12px;
	height: 4px;
	border: none;
	background: #ff1700;
	border-radius: 0;
	transform: rotate(-45deg);
	transition-delay: 50ms;
	right: 6px;
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
	top: 10px;


}
.nav-item.dropdown.show > .nav-link-btn > .dropdown-toggle::after {
	width: 18px;
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
}
.nav-item.dropdown.show > .nav-link-btn > .dropdown-toggle::before {
	width: 18px;
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
}

.dropdown-menu .show > .dropdown-toggle::after {
	width: 18px;
	
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
}
.dropdown-menu .show > .dropdown-toggle::before {
	width: 18px;
	transition: .3s cubic-bezier(.8,.5,.2,1.4);
}





.dropdown-item:focus, .dropdown-item:hover {
	color: #1c1c1d;
	text-decoration: none;
	background-color: transparent;
}

.dropdown-menu {
	box-shadow: 15px 20px 32px rgba(95, 95, 95, 0.2), 2px 2px 7px rgba(0, 0, 0, 0.15);
}
.list-group .dropdown-toggle::after, .list-group .dropdown-toggle::before {
	top: 25px;
}
.bd-home-slider {
	width: 100%;
	height: 100%;
	background-position: top center;
	background-size: 100% auto;
	background-repeat: no-repeat;
}
@media (max-width: 768px) {

.bd-home-slider {

	background-size: auto 100%;

}
}
@media (min-width: 992px) {
	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}


}
@media (min-width: 768px) {
	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}



}
.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
}
@media (min-width: 992px) {
	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: flex!important;
		flex-basis: auto;
	}
}
@media (max-width: 992px) {
	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: grid !important;
		flex-basis: auto;
	}
}
@media (max-width: 992px) {
	.collapse:not(.show) {
		display: none !important;
	}
}
@media (max-width: 992px) {
	.navbar-expand-md .navbar-toggler {
		display: block !important;
	}
}

@media (max-width: 992px) {
	.fixed-top.bg-dark {
		background-color: #000!important;
	}
	.navbar-brand {
		display: inline-table;
	}
.navbar-bottom {
	display: flex;
	width: 100%;
}


}








	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: flex!important;
		flex-basis: auto;
	}


	.navbar-expand-md.navbar-fix-home .navbar-collapse {
		display: grid !important;
		flex-basis: auto;
	}


	.navbar-fix-home .collapse:not(.show) {
		display: none !important;
	}


	.navbar-expand-md.navbar-fix-home .navbar-toggler {
		display: block !important;
	}


	.navbar-expand-md.navbar-fix-home .navbar-nav {
		-ms-flex-direction: column;
		flex-direction: column ;
	}


	.fixed-top.bg-dark {
		background-color: #000!important; 
	}
	.navbar-brand {
		display: inline-table;
	}
.navbar-bottom {
	display: flex;
	width: 100%;
}

.top-scroll {
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 55px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    background-color: #1c1c1d;
    padding: 12px 5px 8px 5px;
    border-radius: 15px;
    cursor: pointer;
}
.page-item:last-child .page-link {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.page-link {
    position: relative;
    display: block;
    height: 36px;
    font-size: 14px;
    margin-left: -2px;
    line-height: 1.25;
    color: #222; 
    background-color: #fff;
    border: 2px solid #000000;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #e68425;
    border-color: #e68425;
}

.page-link:hover {
    z-index: 2;
    color: #ffffff;
    text-decoration: none;
    background-color: #e68425;
    border-color: #e68425;
}


.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
background-color: #ff1700;
    border-color: #ff1700;
}

.page-link:hover {
    z-index: 2;
    color: #ff1700;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}
.page-link {
    position: relative;
    display: block;
    padding: 8px 14px;
    margin-left: 0;
    line-height: 1.25;
    color: #222;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 40px;
    text-transform: uppercase;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.page-item.disabled .page-link {
    color: #efefef;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: transparent;
}
// .fotorama__arr--next {

//     background-position: -32px 0 !important;
// }
.jGrowl {
    font-size: 16px !important;
    top: 10% !important;
}

@media (max-width: 768px) {
    .jGrowl {
        top: 2% !important;
    }
}

.jGrowl-notification {
    opacity: 1 !important;
    width: 320px !important;
    padding: 15px !important;
    border-radius: 15px !important;
}

.jGrowl-notification .jGrowl-close {
    font-size: 24px !important;
    line-height: 24px;
}

.jGrowl-closer {
    width: 320px !important;
    border-radius: 10px !important;
}



#canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000;
    top: 0;
    left: 0;
}
#canvas:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    transition: all .55s ease-in-out
}



.home-slider.example #canvas:after {
    content: "";
    background: rgba(0,0,0,.55);
    transition: all .45s ease-in-out
}




.navbar-fix {
	display: block !important;
    position: fixed;
    top: 75px;
    right: 50px;
}
.navbar-fix .nav-link {
display: inline-grid;
    padding: 4px 12px!important;

    float: right;
}
.navbar-fix .nav-item {
padding: 2px;
}








.navlink {
	text-align: right;;
}
.navbtn {
	text-align: right;
}
.navlink a {
    text-transform: uppercase;
    color: #000;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;

    transition: font-size .15s ease-out;
}


.navtext {
font-size: 24px;
    border-right: none;
    padding: 6px 0 5px 30px!important;
    margin-right: 0;
    vertical-align: middle;
    z-index: 9;
    display: inline-block;


    transition: background .2s .5s ease-in-out, color .1s ease-in-out, width .6s ease-in-out;
    position: relative;
}
.navlink:hover .navtext {


    margin-right: 0;
    border-right: none;
    transition: color .6s .5s ease-in-out, width .3s ease-in-out;
}
.navlink:hover a:after {
   width: 50px;
}
.navlink.active .navtext {
	color: transparent;
	overflow: hidden;
    width: 100%;
    border-right: none;
    transition: color .6s .2s ease-in-out, width .3s ease-in-out;
}
.navitem-top .navlink.navactive .navtext {
	color: #222;
}
.navlink.navactive .navtext {
	color: #ff1700;

    transition: color .2s .5s ease-in-out, width .5s ease-in-out;
}
.navitem-right .navlink .navtext {
	color: transparent;
}

.navitem-right .navlink.navactive .navtext {
    color: #ff1700;
    text-shadow: 0 0 4px #ff1700;
}
.navitem-right .navlink.navactive:hover .navtext {
	text-shadow: 0 0 6px #ff7466;
}

.navitem-right .navtext {
	font-size: 36px;
}


.navlink {
	opacity: 0;
	transition: opacity .1s .1s ease-in-out, color .1s .1s ease-in-out;

}
.navlink.navactive {
	opacity: 1;
	transition: opacity .3s .1s ease-in-out, color .1s .1s ease-in-out;
}


.navitem li {
	margin-bottom: 0;
}
.navitem-right {
    top: 30%;
    position: fixed;
    right: 45px;
}
.navbar-fix-home {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
}
.navbar-text {
	width: 100%;
}
.navbar-text h1 {
    text-transform: uppercase;
    font-family: Gilroy-Regular,serif;
    font-size: 24px;
    padding: 8px 0;
        letter-spacing: .2rem;
}
.navbar-brand {
	    position: absolute;
    top: 10px;
    left: 28px;
}
.cosmodreams {
	    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.title-cosmodreams {
position: relative;
width: 1100px;
    height: 130px;
    z-index: 2;
    margin-right: auto;
    margin-left: auto;
        
}


.title-cosmodreams:after {
content: "";
position: absolute;
    background-image: url(../img/cosmodreams.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 130px;
    z-index: 2;
    left: 0;
    top: 0;
    margin-right: auto;
    margin-left: auto;
        pointer-events: none;
         transition: all .25s ease-in-out;
}

.loading-scen {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(../img/stereo/a1.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	top: 0;
	left: 0;
	// opacity: 0.4;
	// transition: all 1.5s ease-in-out;
}
.loading-scen.loading-complete {
	opacity: 0;
	transition: all 1.5s ease-in-out;
}


.title-cosmodreams.active:after {
	content: "";
	filter: blur(3px);
	 transition: all .25s ease-in-out;


}



.sun-cosmodreams {
    background: url(../img/css_sprites4.png) no-repeat 0 0;
    background-size: auto 100%;
    height: 185px;
    width: 185px;
    position: absolute;
    top: -24%;
    left: 33.82%;
    z-index: 1;
    opacity: 0;
    animation: sprite 8.5s steps(119) infinite;

}
.sun-cosmodreams.active {
	opacity: 1;
	transition: opacity 1.55s ease-in-out;
}




@keyframes sprite {
  from { background-position: 0 0; }
  to { background-position: 100% 0; }
}



@media (max-width: 1300px) {
.title-cosmodreams { 

width: 720px;
    height: 130px;
        
}
.sun-cosmodreams {
height: 120px;
    width: 120px;
    top: 2%;
    left: 33.9%;
}
.navbar-text h1 {
    font-size: 25px;
    padding: 5px 0;
}

} 
@media (max-width: 768px) {
.title-cosmodreams {

        width: 510px;
    height: 130px;
        
}
.sun-cosmodreams {
    height: 110px;
    width: 110px;
    top: 7%;
    left: 31.4%;


}
.navbar-text h1 {
        font-size: 16px;
    padding: 5px 0;
    margin-top: 100px;
}
.title-cosmodreams.active:after {
    content: "";
    -webkit-filter: blur(1.7px);
    filter: blur(1.7px);

}
}
@media (max-width: 560px) {
	.title-cosmodreams {

           width: 380px;
    height: 130px;
        
}
.sun-cosmodreams {
height: 90px;
    width: 90px;
    top: 14%;
    left: 30.2%;
}
}

@media (max-width: 420px) {
	.title-cosmodreams {

           width: 300px;
    height: 130px;
        
}
.sun-cosmodreams {
    height: 75px;
    width: 75px;
    top: 20%;
    left: 29.5%;
}
}

.item-vertical {
	height: 710px;
}
.item-horizontal {
    height: 350px;
}

// .category-gallery [class^="col-"], .category-gallery [class*=" col-"] {
//     padding-right: 5px;
//     padding-left: 5px;

// }
// .category-gallery .card {
//     margin-top: 0;
//     margin-bottom: 10px;
// display: flex;
//     justify-content: center;
//     align-items: center;
// }






// .category-gallery .card-group .card {
//     margin-top: 0;
//     margin-bottom: 0;
//     margin-left: 5px;
//     margin-right: 5px;
// }

// .category-gallery .card-group {
//     margin-right: -5px !important;
//     margin-left: -5px !important;
// }

// .category-gallery {
//     margin-right: 0px !important;
//     margin-left: 0px !important;
// }
// .category-gallery [class^="item-image-"], .category-gallery [class*=" item-image-"] {
//     position: absolute;
// }




















// .category-shop [class^="col-"], .category-shop [class*=" col-"] {
//     padding-right: 5px;
//     padding-left: 5px;

// }
// .category-shop .card {
//     margin-top: 0;
//     margin-bottom: 10px;
// display: flex;
//     justify-content: center;
//     align-items: center;
// }






// .category-shop .card-group .card {
//     margin-top: 0;
//     margin-bottom: 0;
//     margin-left: 5px;
//     margin-right: 5px;
// }

// .category-shop .card-group {
//     margin-right: -5px !important;
//     margin-left: -5px !important;
// }

// .category-shop {
//     margin-right: 0px !important;
//     margin-left: 0px !important;
// }
// .category-shop [class^="item-image-"], .category-shop [class*=" item-image-"] {
//     position: absolute;
// }








// .item-image-patriotism {


// }
.item-image-legacy {
height: 130%;

}
.item-image-deep-space {
    height: 145%;
    right: 0%;

}
.item-image-dystopia {
    height: 145%;
    right: 0%;
     	top: 0%;
}
.item-image-life-on-earth {
    height: 130%;
}
.item-image-life-in-space {
    height: 130%;

}






.lead {
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
        margin-bottom: 20px;
}
.nav-footer .nav-link {
	border-bottom: 2px solid #fff;
	transition: all .15s ease-in-out;
	display: initial;
	padding: 3px 0;
}
.nav-footer .nav-link:hover {
	border-bottom: 2px solid transparent;
}
.nav-footer img {
	       width: 100px;
	    display: block;
    margin-left: auto;
    margin-right: auto;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: none;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(91,91,91,1) 50%, rgba(0,0,0,1) 100%);
    width: 100%;
    height: 1px;
}
header .navbar-fix-home .navbar-brand, header .navbar-fix-home .navbar-text {
	opacity: 0;
	    top: -160px !important;
	transition: all .25s ease-in-out;

}
header.active .navbar-fix-home .navbar-brand, header.active .navbar-fix-home .navbar-text {
	opacity: 1;
	    top: 25px!important;
	transition: all .25s ease-in-out;
}


.title-gallery {
	position: absolute;
    top: 20%;
    left: 30px;
    opacity: 0;
	z-index: 1;
	transition: all .25s ease-in-out, opacity .15s ease-in-out;
	color: red;

}



// .category-gallery .card:hover .title-gallery {
// 	top: 30%;
// 	opacity: 1;

// }


// .category-gallery .card:after {
// 	content: "";
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0;
// 	left: 0;
// 	background: transparent;
// 	transition: all .25s ease-in-out
// }
// .category-gallery .card:hover:after {
// 	content: "";
// 	background: rgba(0, 0, 0, 0.6);

// }

// .category-gallery img {
// 	transform: scale(1);
// 	transition: all .25s ease-in-out
// }
// .category-gallery .card:hover img {
// 	transform: scale(1.1);
// }



// .category-shop .card .title-gallery {
// 	top: 30%;
// 	opacity: 1;

// }

// .category-shop .card:hover .title-gallery {
// 	top: 20%;
// 	opacity: 0;

// }

// .category-shop .item-horizontal {
//     height: 260px;
// }

// .category-shop .card:after {
// 	content: "";
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0;
// 	left: 0;
// 	background: rgba(0, 0, 0, 0.6);
// 	transition: all .25s ease-in-out
// }
// .category-shop .card:hover:after {
// 	content: "";
// 	background: transparent;

// }

// .category-shop img {
// 	transform: scale(1);
// 	transition: all .25s ease-in-out
// }
// .category-shop .card:hover img {
// 	transform: scale(1.1);
// }



.language .dropdown-menu {
	min-width: auto;
	 text-align: left;
	     width: 54px;
	     text-align: center;
}
.language .dropdown-item {
    padding: 7px 0 7px 0 !important;
}


.language {
    position: fixed;
    display: block;
    bottom: 10px;
        right: 45px;
    z-index: 5;
}

.music {
    position: fixed;
    display: block;
    bottom: 10px;
      right: 105px;
    z-index: 5;
}
.phone-btn {
    position: fixed;
    display: block;
    bottom: 10px;
      right: 102px;
    z-index: 5;
}
.phone-btn a {
height: 35px;
    width: 100%;
    display: block;
    padding: 6px 18px 5px 18px;
    margin-right: 0;
    background: #ececec;
    color: #222;
    vertical-align: middle;
    z-index: 9;
    border-radius: 20px;
    border: none;
}


.btn-music {
    padding: 0;
    height: 36px;
    padding: 6px 18px 5px 18px;
    margin-right: 0;
    background: #222;
    color: #fff;
    vertical-align: middle;
    z-index: 9;
    border-radius: 20px;
    border: none;
}



.btn-music img {
    width: 18px;
    margin-bottom: 2px;
}


.btn-language {
    font-size: 16px;
    padding: 0;
    height: 36px;
    padding: 6px 18px 5px 18px;
    margin-right: 0;
    background: #ff1700;
    color: #fff;
    vertical-align: middle;
    z-index: 9;
    border-radius: 20px;
    border: none;
}

.navbar-brand img {
	    width: 90px!important;
}
@media (max-width: 768px) {
	.navbar-brand img {
	    width: 65px!important;
}
}
@media (max-width: 1600px) {
.item-horizontal {
    height: 300px;
}
.item-vertical {
    height: 610px;
}
}
@media (max-width: 62em) {
.featurette-heading {
    margin-top: 110px;
    margin-bottom: 60px;
        letter-spacing: .2rem;
}
}

@media (min-width: 1200px) {
.card-group-horizontal {
    display: block;
    flex-flow: row wrap; 
}

.card-group-horizontal>.card {
    flex: 1 0 0%;
    margin-bottom: 0;

}
// .category-gallery .card-group-horizontal .card {
// margin-bottom: 10px;
// }
// .category-shop .card-group-horizontal .card {
// margin-bottom: 10px;
// }
}

@media (max-width: 768px) {
.card-group-horizontal {
display: block;
    flex-flow: row wrap; 
}

.card-group-horizontal>.card {
    flex: 1 0 0%;
    margin-bottom: 0;

}
// .category-gallery .card-group-horizontal .card {
// margin-bottom: 10px;
// }
// .category-gallery .card-group {
// 	    display: block;
// }
// .category-shop .card-group-horizontal .card {
// margin-bottom: 10px;
// }
// .category-shop .card-group {
// 	    display: block;
// }
}
.navbar-nav .dropdown-menu {
    position: absolute;
    float: none;
    left: auto;
    right: 0;
    top: 30px;
}

@media (min-width: 991px) {
.nav-link-btn.active a {
	opacity: 0.4;
	transition: opacity .15s ease-in-out;
}
.navlink:hover .nav-link-btn.active a {
	opacity: 1 !important;
	transition: opacity .15s ease-in-out;
}
}




// @media (max-width: 991px) {
// 	.category-gallery .card-group  .card{
// 		margin-bottom: 10px;
// 	}
// 	.category-shop .card-group  .card{
// 		margin-bottom: 10px;
// 	}
// }

@media (max-width: 1200px) {
  .item-image-patriotism {
    width: 115%;
    height: auto;
}
}
@media (max-width: 991px) {

  .item-image-patriotism {
    width: 115%;
    height: auto;
}
.item-image-legacy {
	    width: 115%;
    height: auto;
}
.item-image-deep-space {
	    width: 115%;
    height: auto;
}
.item-image-dystopia {
		    width: 115%;
    height: auto;
}
.item-image-life-on-earth {
			    width: 115%;
    height: auto;
}

.item-image-life-in-space {
			    width: 115%;
    height: auto;
}


}

@media (max-width: 768px) {
.item-vertical, .item-horizontal {
	height: 600px !important;
}
  .item-image-patriotism {
height: auto;
    width: 100%;
}
.item-image-legacy {
    height: 115%;
    width: auto;
        top: -10%;
}
.item-image-deep-space {
height: auto;
    width: 100%;
}
.item-image-dystopia {
height: auto;
    width: 100%;
}
.item-image-life-on-earth {
    height: 115%;
    width: auto;
}

.item-image-life-in-space {
    height: 115%;
    width: auto;
}
.img-text {
display: block !important;
    margin-top: 15px !important;
}

}

.img-text img {
	width: 65px;
    height: 60px;
}


@media (max-width: 576px) {
.item-vertical, .item-horizontal {
	height: 300px !important;
}
.item-image-patriotism {
    height: auto !important;
    width: 115% !important;
}
.item-image-deep-space {
    height: auto !important;
    width: 115% !important;
}
.item-image-dystopia {
    height: auto !important;
    width: 115% !important;
}
// .category-gallery .card .title-gallery {
//     top: 30%;
//     opacity: 1;
// }
// .category-gallery .card:after {
//     content: "";
//     background: rgba(0,0,0,.4);
// }



// .category-shop .card .title-gallery {
//     top: 30%;
//     opacity: 1;
// }
// .category-shop .card:after {
//     content: "";
//     background: rgba(0,0,0,.4);
// }


.footer-bottom {
	    text-align: center !important;
}
.footer-bottom div {
	text-align: center !important;
	float: none !important;
}

}

.home-slider .navbar-text {
	position: absolute;
	width: 100%;

	top: 0;
	left: 0;
}

.navbar-fix .navitem-top {
display: flex;
    flex-direction: row !important;

    position: fixed;
        top: 30px;
    right: 15%;
        z-index: 5;
}
.navbar-fix .navitem-top {
	float: right !important;
    margin-right: 15px !important;
}
.navbar-fix .navitem-top.btnactive .navlink {
	display: block;

}



@media (max-height: 610px) {



.navbar-fix .navlink {
	display: none;
}
.navbar-fix .btnactive .navlink {
	display: block;

}
.language {
      right: 30px;
}
}









.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 2px 4px;
    background: #222;

}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #ff1700;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
    background-color: transparent;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 30px;
}

.owl-carousel .owl-item img {
    display: block;
    height: 100%;
    width: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;


}
.card-img, .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.card-img {
position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 0 !important;
}
.card-box .card-img {
	    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.owl-item .item {
  text-align: center;
}

.card-columns .card {
    transition: all .15s ease-in-out;
	background-color: transparent;
}
.card-columns .card:hover {
	text-decoration: none;
	background-color: #e2e2e2;
}


.card-columns-news .card a {
    transition: all .15s ease-in-out;
	background-color: transparent;
}
.card-columns-news .card:hover a {
	text-decoration: none;
	color: #222;
	background-color: #e2e2e2;
}


.home-slider {
	background: #000;
	width: 100%;
	height: 100%;
	position: absolute;
z-index: 40!important;

}

.card-columns-news .card:hover a .zoom-gallery-poster {
    background: #000000a3;
    transition: all .15s ease-in-out;
}
.zoom-gallery-poster {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
display: flex;
    justify-content: center;
    align-items: center;
}
.zoom-gallery-poster img {
	    position: initial;
    display: block;
    width: 60px!important;
    height: auto!important;
    padding: 15px;
}


.card .zoom-gallery-poster img {
	opacity: 0 !important;
	    transition: all .15s ease-in-out;
}

.card:hover .zoom-gallery-poster img {
	opacity: 1 !important;
}
ol {
	padding-left: 20px;
}

@media (max-width: 991px) {
    .media {
        display: block;
        
    }
    .media .bd-placeholder-img {
    	    display: inline-block;

    }

}

.fixed-top .navbar-brand img {
	width: 110px!important;
}
.navbar-expand-md.fixed-top {
	position: relative;
}
.btn-collapsed {
	display: none;
}
.btn-collapsed.collapsed {
	display: inline-block;
}

.lg-toolbar {
    background-color: transparent;
}
.lg-toolbar .lg-icon {
    color: #fff;
    font-size: 30px;
        width: 50px;

}
.owl-carousel .owl-item.active {
opacity: 1;
    transition: all .15s ease-in-out;
}
.owl-carousel .owl-item {
opacity: 0.2;
    transition: all .15s ease-in-out;
}


[class^="owl-"], [class*=" owl-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ico' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.owl-next:before {
  content: "\e942";
      font-size: 35px;
}
.owl-prev:before {
  content: "\e978";
      font-size: 35px;
}
.owl-prev span, .owl-next span {
	display: none;
}

.owl-prev, .owl-next {
    color: #999;
    font-size: 40px;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 40%;
    z-index: 1080;
    border: none;
    outline: 0;
}
.owl-next  {
    right: 5px !important;
}
.owl-prev 	{
    left: 5px !important;
}

.owl-theme .owl-dots {
	margin-top: 15px;
		

}
.owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent;
    color: red;
    text-decoration: none;
}
.dropdown-item:after {
	display: none !important;
}
.owl-theme .owl-nav {
    position: absolute;
    width: 100%;
      top: 190px;
}
.owl-prev:before, .owl-next:before {
	color: #ff1700;
}
@media (max-width: 768px) {
.owl-prev:before, .owl-next:before {
	color: #fff !important;
}
}
@media (max-height: 768px) {
	.owl-theme .owl-nav {

 top: 42%;
}

}


.icon-col-black img {
	width: 45px;
    margin-bottom: 15px;
}
.icon-svg .icon-col-black img {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
    fill: red;
}
.icon-svg h5 {
    font-family: Gilroy-Regular, serif;
    margin: 0;
    font-size: 18px;
    letter-spacing: .15rem;
    text-transform: capitalize;
}

.container-video {
	background: #000;
}
.modal-full {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    margin: 0px auto;
}
.modal-full .modal-content {
	    width: 100% !important;
    height: 100% !important;
    border-radius: 0;
        background-color: #fff;
}
.navitem-right:before {
	content: "";
	height: 100%;
	position: fixed;
	width: 0px;
	right: 0;
	top: 0;
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(246,246,246,0.47) 47%, rgba(255,255,255,1) 100%);
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transition: all 2s ease-in-out;
    

}

.navitem-right.btnactive:before {
content: "";
    height: 100%;
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to right,rgba(255,255,255,0) 0,rgba(246,246,246,.47) 47%,#fff 100%);
    z-index: 0;
    pointer-events: all;
    opacity: 1;
    transition: all .45s ease-in-out;

}
.navbar-fix {
	pointer-events: none;
}
.navbar-fix.btnactive {
	pointer-events: all;
}

.marketing a {
color: #222;
    text-shadow: 0;
    transition: all .15s ease-in-out;
}

.marketing a:hover {
color: #ff1700;
    text-shadow: 0 0 4px #ff7466;
    transition: all .15s ease-in-out;
}
.marketing a img {
	height: 100%;
} 

.marketing .body-img {
	height: 395px;
}
.marketing .body-img-movie {
	height: 270px;
}
.app-group img {
    height: auto !important;
}

.container-home .navitem-right:before {
    background: linear-gradient(to right,rgba(0, 0, 0, 0) 0,rgba(0, 0, 0, 0.47) 47%,#000 100%);
}


.container-home .navitem-right.btnactive:before {
   background: linear-gradient(to right,rgba(0, 0, 0, 0) 0,rgba(0, 0, 0, 0.47) 47%,#000 100%);
}
.container-home .navitem-top .navlink.navactive .navtext {
	color: #fff;
}
.container-home .navlink a {

    color: #fff;

}
.container-home .dropdown-menu a {

    color: #222;

}
.content-center-height {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
}

.container-home .navbar-brand {
	display: none;
}
.press-key {
	text-transform: uppercase;
	position: fixed;
	width: 100%;
	bottom: 10%;
	left: 0;
} 
.press-key span {
	color: #fff;
	font-size: 24px;
	animation: pressKey 0.5s infinite alternate;
}

@-webkit-keyframes pressKey {
  0% {
   opacity: 0.4;
  }

  100% {
   
   opacity: 1;
  }
}
.lg-backdrop {
    background-color: #fff;
}
.lg-toolbar .lg-icon {
    color: #ff1700;
}
.lg-toolbar .lg-icon:hover {
    color: #ff1700;
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #ff1700;
}
.lg-actions .lg-next, .lg-actions .lg-prev {
    color: #ff1700;
}
// .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
//     background: url(../img/fotorama.png) no-repeat !important;
// }

// .fotorama__fullscreen-icon {
//     background-position: 0 -32px !important;

// }

.warning {
    width: 100%;
    height: 100% !important;
    max-height: 100%;
    top: 0;
    position: fixed !important;
    left: 0;
    background: #fff;
    color: #222;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.warning .close {
	position: absolute;
    top: 5px;
    right: 10px;
    opacity: 1;
        color: #ff1700;
            text-shadow: none;
}

.img-warning {
width: 100px;
    height: 85px;
	position: relative;
	display: inline-block;
}

.img-warning:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../img/warning.png);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
}
.img-warning:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 26px;
    background-image: url(../img/equalizer-on.gif);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 37%;
    bottom: 8px;
}

.desktop-press {
	display: block;
}
.desktop-mobile {
	display: none;
}

.trailer-video {
	padding-bottom: 15px;
}

@media (min-width: 992px) {
.body-img iframe {
	height: 360px
}
}

@media (max-height: 780px) {
    .marketing .body-img {
        height: 320px;
    }
    .marketing .body-img iframe {
    	height: 100%;
    }

.trailer-video {
	    padding-left: 60px;
	    padding-right: 60px;
}


}
@media (max-height: 660px) {
    .marketing .body-img {
        height: 250px;;
    }
    .marketing .body-img iframe {
    	height: 100%;
    }

.trailer-video {
	    padding-left: 15px;
	    padding-right: 15px;
}

}



@media (max-width: 992px) {
	.navbar-expand-md.navbar-fix-home .navbar-nav {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
.navbar-fix .navitem-top {
    display: flex;
    flex-direction: row!important;
        position: relative;
        margin-right: 0 !important;
top: 0;
    right: 0;
    z-index: 5;
}
.navitem-right {
    top: 0;
        position: relative;
    right: 0;
}
.navitem-right .navtext {
    font-size: 24px;
}	
.navtext {
	font-size: 24px; 
}

.navbar-nav .dropdown-menu {
    left: auto;
    right: 100%;
    top: -4px;
}
.navitem-right:before {
	transition: all 0.5s ease-in-out;
}
.navitem-right.btnactive:before {
	background: #fff;
	    width: 100%;
}
.marketing .body-img {
    height: auto;
}

.about-img {
	margin-top: 60px;
    margin-bottom: -80px;
    width: 180px;
}
.screen-page-box .page-title {

    font-size: 140px;
}
.desktop-press {
	display: none;
}
.desktop-mobile {
	display: block;
}

}

.mt-10, .my-10 {
    margin-top: 6rem !important;
}
@media (max-width: 768px) {
    .mt-10, .my-10 {
        margin-top: 6rem !important;
    }
}
@media (max-width: 768px) {

.card .zoom-gallery-poster img {
    opacity: 1!important;
   
}




.navbar-nav .dropdown-menu {
    position: relative;
    float: none;
    left: auto;
    right: 0;
    top: 0;
}
.navbar-expand-md.navbar-fix-home .navbar-nav {
        width: 100%;
}
.dropdown-menu li {
    background: transparent;
    display: block;
    border-radius: 0;
    margin-bottom: 4px!important;
    box-shadow: none;
}
.dropdown-item {
    padding: 7px 0px 7px 10px;

}
.navbar-fix {
overflow-y: scroll;
    height: 461px;
    padding-right: 15px;
    top: 75px;
    padding-left: 15px;
    right: 20px;
}
.press-key span {

    font-size: 24px;
}
.body-img img {
	width: 100%;
	height: auto;
	    padding: 0 30px;
}
.social-media {
	margin-bottom: 55px;
}
.social-footer:after {
    height: 145px;
}

.media {
	    text-align: center;
}
}



.about-img {
margin-bottom: -20px;
    width: 190px;

}
.cosmo-img {
    margin-bottom: 30px;
    width: 560px;
    margin-top: 20px;
}
@media (max-width: 1200px) {
    .cosmo-img {
        margin-bottom: 30px;
        width: 500px;
        margin-top: 40px;
    }
}
@media (max-width: 820px) {
    .cosmo-img {
        margin-bottom: -60px;
        width: 400px;
        margin-top: 20px;
    }
}
@media (max-width: 580px) {
    .cosmo-img {
        margin-bottom: -90px;
        width: 250px;
        margin-top: 50px;
    }
}

.height-center {
    height: 100%;
    position: absolute;
    width: 100%; 
    display: inline-table;
}
.height-container {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.height-container .breadcrumb {
margin-top: 25px!important;
}
.img-text {
    display: inline-flex;
    margin-top: -18px;
}
.modal-body .height-container{
	display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
.height-center {
    position: relative !important;
}
	.height-container {
		    padding-top: 80px;


	}
.modal-body .height-container{
	display: flex;
    justify-content: normal;
    align-items: normal;
}

}

@media (max-height: 730px) {
.height-center {
	padding-bottom: 45px;
	padding-top: 45px;

}



}	
@media (max-height: 550px) {
.navbar-fix {

        padding-bottom: 130px;
        padding-top: 0;


}
.height-center {
	padding-bottom: 45px;
}
.height-container .breadcrumb {
margin-top: 45px!important;
}
}


@media (max-width: 768px) {
.height-center {
	    padding-bottom: 150px;


}
}


.plyr__control--overlaid {
	    background: #ff1700!important;
}
.plyr--full-ui input[type=range] {
	    color: #ff1700 !important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
     background: #ff1700!important;
}
.plyr audio, .plyr iframe, .plyr video {

    height: 100.2%;
    width: 100.2%;

}

.vivid-card .card-body {
position: absolute;
    bottom: 100%;
    background: #fff;
    left: 0;
    top: 100%;
    opacity: 0;
    width: 100.3%;
    pointer-events: none;
    min-height: max-content;
    z-index: 5;
    display: inline-table;
    padding: 15px 15px;
    transition: all .08s .08s ease-in-out;
    box-shadow: rgba(95,95,95,.2) 15px 20px 32px, rgba(0,0,0,.15) 2px 2px 7px;
}

.vivid-card .card:hover .card-body {
	opacity: 1;
	pointer-events: all;
}
@media (max-width: 768px) {
.vivid-card .card-body {
	opacity: 1;
	position: relative;
	box-shadow: none;
}

}




.card-grid .card-body {
    position: absolute;
    bottom: 100%;
    background: #fff;
    left: 0;
    top: 100%;
    opacity: 0;
    width: 100.3%;
    pointer-events: none;
    min-height: max-content;
    z-index: 5;
    display: inline-table;
    padding: 15px 15px;
    transition: all .08s .08s ease-in-out;
    box-shadow: rgba(95,95,95,.2) 15px 20px 32px, rgba(0,0,0,.15) 2px 2px 7px;
}
.card-grid .card-box-right .card-body {
    position: relative;
    bottom: 0;
    background: #fff;
    left: 0;
    top: auto;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: inline-table;
    padding: 15px 15px 8px 15px;
    transition: all .08s .08s ease-in-out;
    box-shadow: none;
}
.card-grid .card-box-right.card:hover .card-body {
    box-shadow: none;
}

.news-name {
    position: absolute;
    width: auto;
    height: 40px;
    left: 0;
    text-align: center;
    color: #fff;
    background: #ff1700;
    padding: 10px 15px;
    line-height: 21px;
    bottom: 0;
        text-transform: uppercase;
}
.card-grid .card:hover .card-body {
	opacity: 1;
	pointer-events: all;
}
@media (max-width: 768px) {
    .card-grid .card-body {
	    opacity: 1;
	    position: relative;
	    box-shadow: none;
    }
}
.card-grid h5 a {
    
    color: #ff1700 !important;
    background: transparent !important;
}
.card-grid h5 a:hover {
	color: #222 !important;
	text-decoration: none !important;

}


.marketing .card-img {
	background-position: center;
    background-size: cover;
}







// #shop .card-border {

// }
#shop .card-box {
	    margin-bottom: 0px;
	    padding: 30px;
}
#shop .card-img-shop {
	    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 100%;
    height: 100%;
    z-index: 1;
}
#shop .card-img {
display: flex;

    box-shadow: 0px 0px 0px 2px rgb(193, 215, 248);
    
}
#shop .card-img:hover {
	box-shadow: #fff 0 0 0 5px, rgba(95,95,95,.2) 15px 20px 32px, rgba(0,0,0,.25) 1px 1px 8px;
	z-index: 10;
	
}
#shop .zoom-gallery-poster {
text-align: left;
    display: block;
    justify-content: normal;
    align-items: normal;
}

#shop .card-body {
    position: absolute;
    bottom: 100%;
    background: #fff;
    left: 0;
    pointer-events: none;
    opacity: 0;
    width: 100.1%;
    min-height: max-content;
    top: 100%;
    z-index: 5;
    display: inline-table;
    padding: 15px 15px;
    box-shadow: rgb(255,255,255) 0px 0px 0px 5px, rgba(95,95,95,.2) 15px 20px 32px, rgba(0,0,0,.15) 2px 2px 7px;
}

#shop .card:hover .card-body {

    pointer-events: all;
	opacity: 1;
	z-index: 3

}

        .card-catalog-shop .card-box {
       padding: 60px !important;


        }


@media (max-width: 768px) {
#shop .card-body {
	opacity: 1;
	position: relative;
	box-shadow: none;
	    top: 0;
}
#shop .card-img-shop {
	    height: 360px;
}
#shop .card-img {
	height: auto !important;
}
.card-catalog-shop .card-box {
    padding: 35px!important;
}
.zoom-gallery-poster img {
	display: none;
}
}


.sculpture-card .card-body {
position: absolute;
    bottom: 100%;
    background: #fff;
    left: 0;
    top: 100%;
    opacity: 0;
    width: 100.3%;
    pointer-events: none;
    min-height: max-content;
    z-index: 5;
    display: inline-table;
    padding: 15px 15px;
    transition: all .08s .08s ease-in-out;
    box-shadow: rgba(95,95,95,.2) 15px 20px 32px, rgba(0,0,0,.15) 2px 2px 7px;
    pointer-events: none;
}

.sculpture-card .card:hover .card-body {
	opacity: 1;
	pointer-events: all;
	z-index: 3
}
@media (max-width: 768px) {
.sculpture-card .card-body {
	opacity: 1;
	position: relative;
	box-shadow: none;
}

}
.app-group {
display: flex;
}

.app-group a {
	width: 50%;
    padding: 1px;
}


.close .lg-close:after {
    content: "\e070";
        font-size: 30px;
    width: 70px;

}

.close .lg-close {
	font-size: 35px;
}

.plyr--video {

    width: 100%;
}
.trailer-video video {
    height: 100%;
    width: 100%;
}

// #msGallery .fotorama__arr--next, #msGallery .fotorama__arr--disabled {
// 	background-position: -32px 0!important;
// }
    




@media (max-width: 1200px) {
   .youtube-html5 iframe {
          height: 230px;
   }
}
@media (max-width: 992px) {
   .youtube-html5 iframe {
          height: 368px;
   }
}

@media (max-width: 768px) {
   .youtube-html5 iframe {
      height: 268px;
   }
}
@media (max-width: 500px) {
   .youtube-html5 iframe {
      height: 160px;
   }
}

.container-text-news {
	margin-bottom: 15px;
}

.box-text-news {
	font-weight: 400;
	color: #222;
	border: 2px solid transparent;
	padding: 5px 15px;
	line-height: 25px;
	font-size: 16px;
	border-radius: 20px;
	text-transform: uppercase;
	background: #efefef;    
	text-align: center;
	box-shadow: none;
	transition: box-shadow 0s ease-in-out;
}
.box-text-news:hover {
	color: #222;
    box-shadow: 0 0 0 2px #ff1700;
    background: #ffffff;
    
}

.container-text-news.active .box-text-news {
	color: #fff;
	background: #ff1700;
	box-shadow: 0;
	transition: box-shadow .15s ease-in-out;
}
.container-text-news.active .box-text-news:hover {
	box-shadow: 0 0 0 2px #ff1700;
}
